import { POLL_FULL_FRAGMENT } from '@/graphql/_Fragments/Poll/Full';

export const FEED_POST_CREATE_FRAGMENT = `
    fragment feedPostCreateFragment on FeedPost {
      uid
      type
      content
      createdTime
      schemaCode
      initiator {
        ... on CommunityUser {
          uid
          firstName
          lastName
          jobTitle
          _followerCount
          pictureFileResource {
              uid
              path
          }
        }
        ... on SubEdition {
            uid
            name
            code
            description
            _followerCount
            logoFileResource {
                uid
                path
            }
        }
        ... on Exhibitor {
          uid
          name
          description
          _followerCount
          logoFileResource {
              uid
              path
          }
        }
        __typename
      }
      links {
        uid
        url
        title
        description
        imageUrl
      }
      medias {
        uid
        mediaFileResource {
          uid
          path
          schemaCode
          fileName
        }
      }
      polls {
        ...pollFullFragment
      }
    }
    ${POLL_FULL_FRAGMENT}
  `;
